<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
      </div>
      <div class="card-body" v-if="Object.keys(ex_admin_scenario_weekly_rating).length !== 0" style="padding-bottom: 0.33rem !important;">
        <h4>Last week rating submitted</h4>
        <hr />
        <div class="row mb-2">
          <div class="col-md-4"><b>Year: </b> {{ ex_admin_scenario_weekly_rating.year.year_number }}</div>
          <div class="col-md-4"><b>Month: </b> {{ ex_admin_scenario_weekly_rating.month.month_name }}</div>
          <div class="col-md-4"><b>Week: </b> {{ ex_admin_scenario_weekly_rating.week.week_number }}</div>
        </div>
        <hr/>
        <h5 style="color: red">**If your marking is below 80%, add a comment and raise an issue**</h5>
      </div>
      <div class="card-body" style="padding-top: 0.33rem !important;">
        <div class="row">
          <div class="col-md-4">
            <label for="name" class="col-form-label col-form-label-sm">Year<span class="custom-required">*</span></label>
            <select v-model="year_id" v-validate="'required'" name="year_id" id="year_id" class="form-control form-control-sm" >
              <option :value="''">Select a year</option>
              <option v-for="(year, index) in years" :key="index"  :value="year.id">{{ year.year_number }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="name" class="col-form-label col-form-label-sm">Month<span class="custom-required">*</span></label>
            <select v-model="month_id" v-validate="'required'" name="month_id" id="month_id" class="form-control form-control-sm" >
              <option :value="''">Select a month</option>
              <option v-for="(month, index) in months" :key="index" :value="month.id">{{ month.month_name }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="name" class="col-form-label col-form-label-sm">Week<span class="custom-required">*</span></label>
            <select v-model="week_id" v-validate="'required'" name="week_id" id="week_id" class="form-control form-control-sm" >
              <option selected disabled :value="''">Select a week</option>
              <option v-for="(week, index) in filteredWeeks" :key="index" :value="week.id">{{ week.week_number }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-for="(admin_scenario_accessory, index) in admin_scenario_accessories" :key="index">
          <div class="col-md-2">
            <label for="name" class="col-form-label col-form-label-sm"><strong>{{ admin_scenario_accessory.name }}</strong><span class="custom-required">*</span></label>
          </div>
          <div class="col-md-4 ml-5">
            <!--              <input type="text" v-model="totalRating[index]" v-validate="'required|max:255'" :disabled="admin_scenario_accessory.admin_scenario_sub_accessories.length > 0 ? true : false" id="name" name="name" class="form-control form-control-sm" :placeholder="admin_scenario_accessory.admin_scenario_sub_accessories.length > 0 ? '' : 'Enter rating'"/>-->
            <input type="text" v-model="totalRating[index]" v-validate="'required|max:255'" :disabled="true" id="name" name="name" class="form-control form-control-sm"/>
            <div class="invalid-feedback">{{ admin_scenario_accessory.name }} is required</div>
          </div>
          <div class="col-md-12 ml-5" v-for="(admin_scenario_sub_accessory, index) in admin_scenario_accessory.admin_scenario_sub_accessories" :key="index">
            <div class="row">
              <div class="col-md-2">
                <label for="name" class="col-form-label col-form-label-sm">{{admin_scenario_sub_accessory.name }}<span class="custom-required">*</span></label>
              </div>
              <div class="col-md-4">
                <input type="number" v-model="admin_scenario_sub_accessory.rating"  v-validate="{ required: true, max: 3, regex: /^[0-9]*$/, max_value: 100 }" :id="'rating_' + admin_scenario_sub_accessory.id" :name="'rating_' + admin_scenario_sub_accessory.id" class="form-control form-control-sm" placeholder="Enter rating from 100"/>
                <div class="invalid-feedback" v-if="errors.has('rating_' + admin_scenario_sub_accessory.id)"><span style="font-weight: 600">{{ admin_scenario_sub_accessory.name }} </span>  rating is required</div>
              </div>
              <div class="col-md-5">
                <input type="text" v-model="admin_scenario_sub_accessory.rating_comment"  v-validate="{ required: admin_scenario_sub_accessory.rating < 80 ? true : false, max: 255 }" :id="'rating_comment_' + admin_scenario_sub_accessory.id" :name="'rating_comment_' + admin_scenario_sub_accessory.id" class="form-control form-control-sm" placeholder="Enter rating comment.."/>
                <div class="invalid-feedback" v-if="errors.has('rating_comment_' + admin_scenario_sub_accessory.id)"><span style="font-weight: 600">{{ admin_scenario_sub_accessory.name }} </span> rating comment is required</div>
              </div>
            </div>
          </div>
        </div>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="admin_scenario_accessories.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
        <hr/>
        <a-button  v-if="admin_scenario_accessories.length > 0" class="btn btn-success waves-effect waves-light mr-2 mt-4" :loading="saveAdminScenarioRating" @click.prevent="saveRating"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Submit Rating</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'create',
  components: { Empty },
  data() {
    return {
      ex_admin_scenario_weekly_rating: {},
      admin_scenario_weekly_ratings: [],
      admin_scenario_accessories: [],
      years: [],
      months: [],
      weeks: [],
      year_id: '',
      month_id: parseInt(new Date().getMonth()) + 1,
      week_id: '',
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      saveAdminScenarioRating: false,
      validation_errors: {},
      show: false,
    }
  },
  mounted: function () {
    this.getAdminScenarioAccessories()
  },
  computed: {
    totalRating() {
      const adminScenarioAccessories = this.admin_scenario_accessories

      let adminScenarioSubAccessories = []
      // eslint-disable-next-line no-return-assign
      const result = adminScenarioAccessories.map((el) => {
        adminScenarioSubAccessories = el.admin_scenario_sub_accessories
        const rating = adminScenarioSubAccessories.reduce((oldRating, newRating) => parseFloat(oldRating) + parseFloat(newRating.rating), 0)
        const ratingAvg = rating / adminScenarioSubAccessories.length
        return Math.round(ratingAvg) || 0
      })
      return result || 0
    },
    filteredWeeks: function() {
      return this.weeks.filter(function(el) {
        return el.month_id === this.month_id
      }, this)
    },
  },
  methods: {
    getAdminScenarioAccessories() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/admin-scenario/rating/accessories')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.admin_scenario_accessories = response.data.admin_scenario_accessories
          this.years = response.data.years
          this.months = response.data.months
          this.weeks = response.data.weeks
          this.ex_admin_scenario_weekly_rating = response.data.ex_admin_scenario_weekly_rating
          this.admin_scenario_weekly_ratings = response.data.admin_scenario_weekly_ratings
          // get year id
          const year = this.years.find((year) => year.year_number === new Date().getFullYear())
          this.year_id = year.id
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveRating() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.saveAdminScenarioRating = true
          apiClient.post('api/admin-scenario/hub/rating/store', { admin_scenario_accessories: this.admin_scenario_accessories, year_id: this.year_id, month_id: this.month_id, week_id: this.week_id })
            .then(response => {
              this.saveAdminScenarioRating = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
              this.getAdminScenarioAccessories()
            })
            .catch(error => {
              this.saveAdminScenarioRating = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>

</style>
